<template>
    <div style="margin-top: 200px; text-align: center;">
        <h1 style="color: red;">Помилка поповнення.</h1>
        <h3 style="margin-top: 20px;">Если снятие денег произошло, а зачисление на счет - нет.</h3>
        <h3>обратитесь к администрации</h3>
        <h4 style="margin-top: 20px;">Автоматическое перенаправление через 20 секунд</h4>
    </div>
</template>

<script>
export default {
    mounted() {
        setTimeout(() => {
            this.$router.go('/');
        }, 20000);
    },
}
</script>

<style scoped></style>