<template>
  <div v-if="isSmallScreen" class="nav-phone">
    <div class="nav-content-phone" ref="menu">
      <div class="navbar">
        <div class="burger-button" @click="toggleMenu()" >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="logo">
          <navigation-search style="margin-right: 20px; background-color:#111111;" ></navigation-search>
          <router-link to="/" style="display: flex; align-items: center; background-color: #111111;">
            <img src="../Components/Images/Asset.svg" alt="SVG Image" style="width: 30px;background-color: #111111; ">
          </router-link> 
        </div>
      </div>
      <div class="menu" :class="{ 'open': isMenuOpen }" >
        <div v-if="isUserLogined" class="logined-phone">
          <a style="background-color: #111111;">{{ userLogin }} {{ userBalance }} р.</a>
          <a style="background-color: #111111;" href="/catalog">Каталог</a>
          <div class="logout-svg" @click="logoutBtn">
            <svg style="background-color: #111111;" xmlns="http://www.w3.org/2000/svg" width="30" height="30"
              viewBox="0 0 24 24" fill="none">
              <path d="M17.4399 14.62L19.9999 12.06L17.4399 9.5" stroke="white" stroke-width="1.5" stroke-miterlimit="10"
                stroke-linecap="round" stroke-linejoin="round" />
              <path d="M9.76001 12.0601H19.93" stroke="white" stroke-width="1.5" stroke-miterlimit="10"
                stroke-linecap="round" stroke-linejoin="round" />
              <path d="M11.76 20C7.34001 20 3.76001 17 3.76001 12C3.76001 7 7.34001 4 11.76 4" stroke="white"
                stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>
        <div v-else>
          <router-link to="/login" style="background-color: #111111;" @click=(toggleMenu)>
            <h4 style="background-color: #111111; color: white;">Вхід</h4>
          </router-link>
          <router-link to="/Registration" style="background-color: #111111;" @click=(toggleMenu)>
            <h4 style="background-color: #111111; color: white;">Реєстрація</h4>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="nav-desktop">
    <div class="nav-content-desktop">
      <router-link to="/" style="display: flex; align-items: center;">
        <h3 style="color: lightblue;" class="typewriter">Ranobe</h3>
        <h3 style="color: lightyellow;" class="typewriter">Library</h3>
      </router-link>
      <router-link to="/catalog">
        <h4 style="background-color: #111111; color: white;">Каталог</h4>
      </router-link>
      <router-link v-if="this.userRole === 'Admin'" to="/createbook">
        <h4 style="background-color: #111111; color: blue;">Створити книжку</h4>
      </router-link>
      <router-link v-if="this.userRole === 'Admin'" to="/bookstatistic">
        <h4 style="background-color: #111111; color: blue;">Статистика</h4>
      </router-link>
      <div
        style="margin-left: auto; display: flex; justify-content: center; align-items: center; background-color: var(--background-color);">
        <navigation-search style="margin-right: 20px; background-color:#111111;"></navigation-search>
        <div v-if="isUserLogined" class="logined">
          <div style="justify-content: center; align-items: center; display: flex; background-color: #111111;">
            <router-link to="/payment">
              <h3 style="background-color: #111111; color: white;">{{ userLogin }}</h3>
              <h3 style="background-color: #111111; color: white;">{{ userBalance }} р.</h3>
            </router-link>
          </div>
          <div class="logout-svg" @click="logoutBtn">
            <svg style="background-color: #111111;" xmlns="http://www.w3.org/2000/svg" width="30" height="30"
              viewBox="0 0 24 24" fill="none">
              <path d="M17.4399 14.62L19.9999 12.06L17.4399 9.5" stroke="white" stroke-width="1.5" stroke-miterlimit="10"
                stroke-linecap="round" stroke-linejoin="round" />
              <path d="M9.76001 12.0601H19.93" stroke="white" stroke-width="1.5" stroke-miterlimit="10"
                stroke-linecap="round" stroke-linejoin="round" />
              <path d="M11.76 20C7.34001 20 3.76001 17 3.76001 12C3.76001 7 7.34001 4 11.76 4" stroke="white"
                stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>
        <div v-else style="margin-left: auto; background-color: #111111; display: flex;" class="sing-in">
          <router-link to="/login">
            <h4 style="background-color: #111111; color: white;">Вхід</h4>
          </router-link>
          <router-link to="/Registration">
            <h4 style="background-color: #111111; color: white;">Реєстарція</h4>
          </router-link>
        </div>
      </div>


    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import backendURL from "@/config.js";
import NavigationSearch from './Navigation-search.vue';
export default {
  components: {
    NavigationSearch,
  },
  data() {
    return {
      userLogin: '',
      userBalance: '',
      userRole: '',
      isSmallScreen: false,
      isMenuOpen: false,
      isUserLogined: false
    }
  },
  mounted() {
    this.fetchUser();
    window.addEventListener('resize', this.checkScreenSize);
    this.checkScreenSize();
    document.addEventListener('click', this.handleOutsideClick);
  },
  methods: {


    handleOutsideClick(event) {
      if (this.$refs.menu && !this.$refs.menu.contains(event.target)) {
        this.isMenuOpen = false;
      }
    },

    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },

    checkScreenSize() {
      this.isSmallScreen = window.innerWidth <= 800;
    },

    logoutBtn() {
      this.$cookies.remove('token');
      this.$router.go('/')
    },

    async fetchUser() {
      const token = this.$cookies.get('token');
      if (token) {
        try {
          const response = await axios.get(backendURL + 'User/GetUserByToken');
          this.userLogin = response.data.result.login;
          this.userBalance = response.data.result.balance;
          this.userRole = response.data.result.role;
          this.isUserLogined = true;
          return;
        } catch (e) { console.log(e) }
      }
      else {
        this.isUserLogined = false;
      }
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize)
    document.removeEventListener('click', this.handleOutsideClick);
  },
}
</script>
  
<style scoped>
.nav-desktop {
  background-color: #111111;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  text-decoration: none;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  z-index: 9999;

}

.logined {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  background-color: #111111;
}

.logined-phone {
  background-color: #111111;
}

.logout-svg {
  display: flex;
  margin-left: 20px;
  background-color: #111111;
}

.logout-svg:hover {
  cursor: pointer;
}

.nav-content-desktop {
  background-color: #111111;
  max-width: 1280px;
  width: 1280px;
  height: 100%;
  display: flex;
  align-items: center;
  min-width: 100px;
}

.nav-content-desktop a {
  text-decoration: none;
  color: white;
  background-color: #111111;
  margin-right: 30px;
  font-size: 20px;
}

.navbar {
  background-color: #111111;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  color: white;
}

.burger-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  cursor: pointer;
}

.burger-button span {
  display: block;
  width: 100%;
  height: 3px;
  background-color: white;
  border-radius: 3px;
}

.logo {
  background-color: #111111;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo h3 {
  background-color: #111111;
}

.menu {
  background-color: #111111;
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;
}

.menu.open {
  height: 150px;
}

.menu a {
  display: block;
  padding: 10px;
  color: white;
  text-decoration: none;
}

.menu a:hover {
  background-color: #333333;
}

.st0 {
  fill: #212121;
  stroke: #000000;
  stroke-miterlimit: 10;
}

.st1 {
  fill: #FFFFFF;
}

.typewriter {
  background-color: #111111;
  color: #fff;
  font-family: monospace;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 left;
  letter-spacing: .0em;
  text-decoration: none;
}

.typewriter h3 {}
</style>