<template>
    <div style="">
        <Carousel :items-to-show="itemsToShow" >
            <Slide style="align-items: normal;" v-for="book in BookCompilation" :key="book.id">
                <div class="image-container" @click="goToBook(book._id)">
                    <img class="image-space" :src="book.imagePath" alt="Book Image">
                    <div class="text-overlay">
                        <div style="display: flex; text-align: left;"><h4>{{ book.title }}</h4></div>
                    </div>
                </div>
            </Slide>
            <template #addons>
                    <Navigation/>

                </template>
        </Carousel>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { RouterLink } from 'vue-router';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
export default {
    props: {
        BookCompilation: {
            type: Array,
            required: true
        },
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
    },
    data() {
        return {
            itemsToShow: 6,
        }
    },
    mounted() {
        window.addEventListener('resize', this.checkScreenSize);
        this.checkScreenSize();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateScreenWidth);
        this.exported = false;
    },
    methods: {

        goToBook(bookId){
            this.$router.push(`/book/${bookId}`);;
        },

        checkScreenSize() {
            if (window.innerWidth >= 1280) {
                this.itemsToShow = 1280 / 200;
            }
            else {
                this.itemsToShow = window.innerWidth / 200;
            }
        },
    }
}
</script>

<style scoped>
.image-container {
    text-decoration: none;
    color: white;
}
.image-container:hover{
cursor:pointer;
}

.image-space {
    width: 150px;
    height: 230px;
    border-radius: 8px;
}

.text-overlay {
    align-items: left;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: none;
    padding: 5px;
    color: white;
    max-width: 150px;
}

.liked-percent {
    display: flex;
    align-items: left;
}
</style>

<style>
.carousel__icon {
 fill: white;
 border-radius: 90px;
}
.carousel__pagination-button::after{
background-color: white;
}
.carousel__pagination-button--active::after{
background-color: gray;
}
</style>