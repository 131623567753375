<template>
    <div style="display: flex;align-items: center;justify-content: center; margin-top: 15%;">
        <div class="content">
            <h2>Вхід</h2>
            <form @submit.prevent>
                <div style="margin-top: 20px">
                    <h4 v-if="showLoginMessage">Логин має бути не меньше 3 символів</h4>
                    <h4 v-if="showBadMessage">Неправильний пароль або ім'я користувача</h4>
                    <input @input="login = $event.target.value" v-bind:value="login" class="login-input" type="text"
                        placeholder="Ім'я користувача" required>
                </div>
                <div style="margin-top: 20px">
                    <h4 v-if="showPasswordMessage">Пароль має бути не меньше ніж 8 символів</h4>
                    <input @input="password = $event.target.value" v-bind:value="password" class="login-input"
                        type="password" placeholder="Пароль" required>
                </div>
                <button @click="submitForm" style="margin-top: 20px" type="submit" class="login-button">Продовжити</button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import backendURL from "@/config.js";
export default {
    beforeRouteEnter(to, from, next) {
    const token = $cookies.get('token'); 
    if (token) {
      next({ path: '/' });
    } else {
      next();
    }
  },
    data() {
        return {
            login: '',
            password: '',
            showLoginMessage: false,
            showPasswordMessage: false,
            showBadMessage: false,
        }
    },
    methods: {
        submitForm() {
            this.showLoginMessage = this.login.length < 3;
            this.showPasswordMessage = this.password.length < 6;

            if (!this.login || !this.password) {
                return;
            }

            if (this.password.length < 6) {
                return;
            }

            const formData = {
                login: this.login,
                password: this.password
            };

            axios.post(backendURL+'user/login', formData).then(Response => {
                if (Response.data.result == null) {
                    this.showBadMessage = true;
                    return;
                }
                this.showBadMessage = false;
                const token = Response.data.result;
                this.$cookies.set('token', token, '1d');
                this.$router.go('/')
            })
        }
    },
    mounted() {
    }
};
</script>

<style scoped>
.content {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    min-height: 300px;
    max-width: 800px;
    max-height: 800px;
    border-radius: 20px;
    margin: 10px;
}

.login-input {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    color: white;
    border: 2px solid #454545;
    text-decoration: none;
    background-repeat: no-repeat;
    padding: 5px 5px 5px 15px;
}

.login-button {
    border-radius: 20px;
    border: 2px solid #454545;
    width: 100%;
    height: 40px;
    color: white;
    background-color: #454545;
    transition: 0.3s ease-in-out;
}

.login-button:hover {
    background-color: #111111;
}

.content h2 {
    color: white;
    margin-left: 10px;
}

.content h4 {
    color: red;
}
</style>