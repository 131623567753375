<template>
    <div style="display: flex; justify-content: center; margin-top: 50px;">
        <div class="main-content">
            <div>
                <MainPoster></MainPoster>
            </div>

            <div style="min-height: 400px ;">
                <h2 style="color: white; margin: 0px 0px 5px 20px;">По-перше, кращі серії</h2>
                <h4 style="color: #cfcfcf; margin: 0px 0px 15px 20px;">Давайте прочитаємо найкращі історії за жанрами!</h4>
                <SkeletonBooks style="margin-top: 40px;" v-if="!isLoadedBestBooks"></SkeletonBooks>
                <BestBooksCompilation v-else :BestBooksByGenre="BestBooksByGenre"></BestBooksCompilation>
            </div>
            <div style="margin-top:20px;">
                <h2 style="color: white; margin: 0px 0px 5px 20px;">Новинки</h2>
                <h4 style="color: #cfcfcf; margin: 0px 0px 15px 20px;">Останні додані книги</h4>
                <SkeletonBooks style="margin-top: 10px;" v-if="!isLoadedLatestBooks"></SkeletonBooks>
                <BookCompilation v-else :BookCompilation="LatestBooks"></BookCompilation>
            </div>
        </div>
    </div>
</template>

<script>
import backendURL from '@/config.js';
import axios from 'axios';
import BestBooksCompilation from '@/Components/BestBooksCompilation.vue';
import BookCompilation from '@/Components/BooksCompilation.vue';
import SkeletonBooks from '@/Components/SkeletonBooks.vue';
import MainPoster from '../Main-poster.vue';
export default {
    components: {
        BestBooksCompilation,
        SkeletonBooks,
        BookCompilation,
        MainPoster
    },
    data() {
        return {
            BestBooksByGenre: [],
            LatestBooks: [],
            isLoadedBestBooks: false,
            isLoadedLatestBooks: false,
        }
    },
    methods: {
        async GetCompilation() {
            try {
                const Best = await axios.get(backendURL + 'Book/GetBestBooksByGenre');
                this.BestBooksByGenre = Best.data;
                this.isLoadedBestBooks = true;
                const Latest = await axios.get(backendURL + 'Book/GetLatestBooks');
                this.LatestBooks = Latest.data.result;
                this.isLoadedLatestBooks = true;
            }
            catch {

            }
        }
    },
    mounted() {
        this.GetCompilation();
    },
}

</script>

<style scoped>
.main-content {
    max-width: 1280px;
    width: 100%;
    margin-top: 50px;
    color: white;
}
</style>