<template>
    <div style="margin-top: 200px; text-align: center;">
        <h1 style="color: green;">Успешное пополнение.</h1>
        <h3 style="margin-top: 20px;">Если возникла ошибка с зачислением средств</h3>
        <h3>обратитесь к администрации</h3>
        <h4 style="margin-top: 20px;">Автоматическое перенаправление через 5 секунд</h4>
    </div>
</template>

<script>
export default {
    mounted() {
        setTimeout(() => {
            this.$router.push('/');
        }, 5000);
    },
}
</script>

<style scoped></style>