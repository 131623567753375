<template>
    <div class="centered-container">
        <div v-if="isUploaded" class="upload-section">
            <div class="about">
                <input class="input-field" type="text" v-model="book.title" placeholder="Назва книги">
                <input class="input-field" type="text" v-model="book.author" placeholder="Автор книги">
                <div class="genre-choices">
                    <h3>Виберіть жанр:</h3>
                    <div v-for="item in genres" :key="item" class="genre-option">
                        <div class="genre-button" :class="{ active: isSelected(item) }" @click="toggleGenre(item)">
                            {{ item }}
                        </div>
                    </div>
                </div>
                <div class="genre-choices">
                    <h3>Виберіть мову оригіналу:</h3>
                    <div v-for="item in languages" :key="item.id" class="language-option">
                        <div :class="{ 'active': book.originalLanguage === item }" @click="selectLanguage(item)"
                            class="genre-button">{{ item }}</div>
                    </div>
                </div>
                <h3 class="centered-text">Опис</h3>
                <textarea v-model="book.description" class="description-field" placeholder="Введіть опис"></textarea>
                <button class="editButton" @click="ReplaceBook()">Змінити</button>
            </div>
        </div>
    </div>
</template>

<script>
import backendURL from '@/config';
import axios from 'axios';

export default {
    async beforeRouteEnter(to, from, next) {
        const token = $cookies.get('token');
        if (token) {
            const response = await axios.get(backendURL + 'User/GetUserByToken');
            if (response.data.result.role == 'Admin') {
                next();
            }
            else {
                next({ path: '/' });
            }
        }
        else {
            next({ path: '/' });
        }
    },
    data() {
        return {
            book: '',
            genres: ["Бойовик", "Бойові Мистецтва", "Гарем", "Героїчне Фентезі", "міське Фентезі",
            "Джьосей", "Драма", "Історія", "Кодомо", "Комедія", "Кіберпанк", "ЛітRPG",
            "Містика", "Наукова фантастика", "Повсякденність", "Постапокаліпсис", "Пригоди",
            "Психологія", "Романтика", "Надприродне", "Сьодзьо", "Сьонен", "Спорт", "Сейнен",
            "Трагедія", "Трилер", "Жахи", "Фантастика", "Фентезі", "Школа", "Еротика", "Етті", "Юрі", "Яой"],
            languages: ["Англійська", "Китайська", "Корейська", "Японська"],
            isUploaded: false,
        }
    },

    methods: {
        async GetBook() {
            var getBookPath = backendURL + 'Book/GetBookById?bookId=' + this.$route.params.id;
            var bookResponse = await axios.get(getBookPath);
            this.book = bookResponse.data.result;
            this.isUploaded = true;

        },
        isSelected(item) {
            return this.book.genre.includes(item);
        },
        selectLanguage(language) {
            this.book.originalLanguage = language;
        },
        toggleGenre(item) {
            if (this.book.genre.includes(item)) {
                this.book.genre = this.book.genre.filter(genre => genre !== item);
            } else {
                this.book.genre.push(item);
            }
        },
        async ReplaceBook(){
            var result = await axios.put(backendURL + 'adminbook/updatebook', {
                _id: this.book._id,
                title: this.book.title,
                description: this.book.description,
                author: this.book.author,
                originalLanguage: this.book.originalLanguage,
                genre: this.book.genre
            })
            if(result.data == true){
                this.$router.push('/book/' + this.$route.params.id);
            }
            else{
                alert('Что-то не так')
            }
        }
    },
    mounted() {
        this.GetBook();
    },
}
</script>

<style scoped>
.centered-container {
    display: flex;
    justify-content: center;
}

.upload-section {
    margin-top: 80px;
    max-width: 1280px;
    width: 100%;
}

.input-field {
    width: 100%;
    height: 45px;
    border-radius: 20px;
    padding: 10px;
    border: 1px solid gray;
    color: white;
    font-size: 18px;
    margin-top: 20px;

}

.genre-choices {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;

}

.genre-option {
    margin-top: 7px;
    margin-left: 7px;
}

.language-option {
    margin-top: 7px;
    margin-left: 7px;
}

.genre-button {
    display: flex;
    align-items: center;
    height: 20px;
    padding: 17px;
    background-color: #111111;
    border-radius: 5px;
    transition: all 0.2s ease;
    color: white;
}

.genre-button:hover {
    cursor: pointer;
    background-color: #1070b0;
}

.active {
    background-color: #3490dc;
}

.centered-text {
    text-align: center;
    margin-top: 20px;
}

.description-field {
    width: 100%;
    height: 300px;
    border-radius: 20px;
    padding: 10px;
    color: white;
    font-size: 15px;
}
.editButton{
    margin-top: 30px;
    width: 100%;
    height: 40px;
    color: white;
    border-radius: 20px;
    border: 1px solid gray;
    transition: all 0.2s ease;
}
.editButton:hover{
    background-color: #1070b0;
}
</style>