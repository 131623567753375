import { createApp } from 'vue';
import App from './App';
import router from './Components/Router/router';
import VueCookies from 'vue-cookies';
import axios from 'axios';

const app = createApp(App);

app.use(router).use(VueCookies);


axios.interceptors.request.use(config => {
  const token = VueCookies.get('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const backroute = 'http://localhost:5041'
app.config.globalProperties.$axios = axios;
app.mount('#App');
