<template>
    <div class="App">
        <Navigation class="navigation"></Navigation>
        <RouterView></RouterView>
    </div>
</template>

<script>

import { RouterView } from "vue-router";
import Navigation from "@/Components/Navigation.vue"
export default {
    components: {
    Navigation,
    RouterView,
},
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
*{
    font-family: 'Montserrat', sans-serif;
    background-color: #181818;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} 

.navigation{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}
</style>

