<template>
    <div style="display: flex;">
        <div>
            <img class="image-space" :src="book.imagePath" alt="Book Image">
        </div>
        <div style="margin: 10px;">
            <h3>{{ book.title }}</h3>
            <h4 style="margin-top: 5px;">Автор: {{ book.author }}</h4>
            <h4 style="margin-top: 5px;">Мова оригіналу: {{ book.originalLanguage }}</h4>
            <div style="display: flex; margin-top: 5px; align-items: center;">
                <svg style="margin-right: 5px;" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" fill="white" height="15px" width="15px"
                                version="1.1" id="Capa_1" viewBox="0 0 51.997 51.997" xml:space="preserve">
                                <path
                                    d="M51.911,16.242C51.152,7.888,45.239,1.827,37.839,1.827c-4.93,0-9.444,2.653-11.984,6.905  c-2.517-4.307-6.846-6.906-11.697-6.906c-7.399,0-13.313,6.061-14.071,14.415c-0.06,0.369-0.306,2.311,0.442,5.478  c1.078,4.568,3.568,8.723,7.199,12.013l18.115,16.439l18.426-16.438c3.631-3.291,6.121-7.445,7.199-12.014  C52.216,18.553,51.97,16.611,51.911,16.242z" />
                            </svg>
                            <h5>{{ book.likedPercent }} %</h5>
            </div>
            <h4 style="margin-top: 5px;">{{ sliceDescription(book.description) }}</h4>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        book: '',
    },
    methods:{
        sliceDescription(item){
            if(item.length > 200){
                return item.slice(0, 200) + '...'
            }
            else{
                return item;
            }
        }
    }
}
</script>

<style scoped>
.image-space {
    width: 150px;
    border-radius: 20px;
}
</style>