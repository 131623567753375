<template>
    <div class="search-inputs-place">
        <div class="content-search">
            <div class="search-inputs">
                <div class="container-grid">
                    <div style="margin-bottom: 15px;">
                        <h3 style="margin-bottom: 10px;background-color: black;">
                            Сторувати за 
                        </h3>
                        <div style="display: flex; background-color: black; gap: 10px;">
                            <div v-for="(item, index) in sortBy" :key="index">
                                <div class="display-items" :class="{ 'active': searchResponse.sortType === index }"
                                    @click="ChangeSortBy(index)">
                                    {{ item }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h3 style="margin-bottom: 10px;background-color: black;">
                            Мова оригіналу
                        </h3>
                        <div style="display: flex; flex-wrap: wrap; background-color: black; gap: 10px;">
                            <div v-for="(item) in languages">
                                <div class="display-items" :class="{ 'active': searchResponse.originalLanguage === item }"
                                    @click="searchResponse.originalLanguage = item">
                                    <div style="background-color: var(--background-color);" v-if="item === 'Any'">
                                        Люба
                                    </div>
                                    <div style="background-color: var(--background-color);" v-else>
                                        {{ item }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="margin-top: 10px; height: 100%;">
                    <h3 style=" margin-left: 5px; margin-bottom: 10px;background-color: black;">
                        Жанри
                    </h3>
                    <div style="gap: 10px; display: flex; flex-wrap: wrap; width: 100%;">
                        <div v-for="item in genres" :key="item">
                            <div class="display-items" :class="{ active: isSelected(item) }" @click="toggleGenre(item)">
                                {{ item }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="result-place">
        <div class="result-content">
            <div v-if="!isResponseLoaded">
                <h1 style="margin-top: 30px; text-align: center;">Завантаження</h1>
            </div>
            <div style="color: white;">
                <div v-for="item in books" style="margin-top:30px;" :key="item.id">
                    <catalogBook @click="GoToBook(item._id)" :book="item"></catalogBook>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import backendURL from '@/config';
import axios from 'axios';
import catalogBook from '@/Components/Catalog-Book.vue';
export default {
    data() {
        return {
            genres: ["Бойовик", "Бойові Мистецтва", "Гарем", "Героїчне Фентезі", "міське Фентезі",
            "Джьосей", "Драма", "Історія", "Кодомо", "Комедія", "Кіберпанк", "ЛітRPG",
            "Містика", "Наукова фантастика", "Повсякденність", "Постапокаліпсис", "Пригоди",
            "Психологія", "Романтика", "Надприродне", "Сьодзьо", "Сьонен", "Спорт", "Сейнен",
            "Трагедія", "Трилер", "Жахи", "Фантастика", "Фентезі", "Школа", "Еротика", "Етті", "Юрі", "Яой"],
            languages: ["Any", "Англійська", "Китайська", "Корейська", "Японська"],
            sortBy: ["Им'я", 'Нове', 'Популярність'],
            counter: 0,
            books: [],
            needMore: false,
            isResponseLoaded: false,
            skipCounter: 0,
            searchResponse: {
                sortType: 0,
                originalLanguage: 'Any',
                genres: []
            }
        }
    },
    components: {
        catalogBook
    },
    methods: {
        GoToBook(bookID) {
            this.$router.push('/book/' + bookID);
        },
        ChangeSortBy(index) {
            this.searchResponse.sortType = index;
        },
        toggleGenre(item) {
            if (this.searchResponse.genres.includes(item)) {
                this.searchResponse.genres = this.searchResponse.genres.filter(genres => genres !== item);
            }
            else {
                this.searchResponse.genres.push(item);
            }
        },
        isSelected(item) {
            return this.searchResponse.genres.includes(item);
        },
        async getBooks() {
            var request = {
                sortType: this.searchResponse.sortType,
                originalLanguage : this.searchResponse.originalLanguage,
                genres : this.searchResponse.genres,
                skipCounter: this.skipCounter
            }
            var result = await axios.post(backendURL + 'book/advancedsearch', request);
            result.data.forEach(element => {
                this.books.push(element);
            });
            this.isResponseLoaded = true;
            if(result.data != []){
                this.skipCounter += 20;
                this.needMore = true;
            }
        },
        handleScroll() {
            const scrollPosition = window.scrollY;
            const pageHeight = document.documentElement.scrollHeight;
            const windowHeight = window.innerHeight;
            if (this.needMore) {
                if (scrollPosition + windowHeight >= pageHeight - 100) {
                    this.getBooks();
                    this.needMore = false;
                }
            }
        },
    },
    mounted() {
        this.getBooks();
        window.addEventListener('scroll', this.handleScroll);

    },
    watch: {
        searchResponse: {
            handler(nevVal) {
                this.books = [];
                this.skipCounter = 0;
                if (this.needMore) {
                    this.getBooks()
                }
            },
            deep: true
        }
    }
}
</script>

<style scoped>
.search-inputs-place {
    height: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.result-place {
    background-color: #181818;
}

.result-place div,
h1,
h2,
h3,
h4 {
    background-color: #181818;
}

.content-search {
    width: 100%;
    max-width: 1280px;
    margin-top: 100px;
    color: white;
    padding: 30px;
}

.search-inputs-place h3,
h4,
h2,
div {
    background-color: black;
}

.display-items {
    background-color: #111111;
    padding: 10px;
    border-radius: 15px;
    transition: all 0.2s ease;
}

.display-items:hover {
    cursor: pointer;
    background-color: #1070b0;
}

.active {
    background-color: #3490dc;
}

.container-grid {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(auto, 1fr));
    grid-column-gap: clamp(1rem, 1vw, 10rem);
}

@media screen and (min-width: 1280px) {
    .container-grid {
        grid-template-columns: repeat(auto-fit, minmax(600px, 2fr));
    }
}

.result-place {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}

.result-content {
    max-width: 1280px;
    width: 100%;
}
</style>