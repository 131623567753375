<template>
    <div style="">
        <Carousel :items-to-show="itemsToShow">
            <Slide style="align-items: normal;" v-for="book in BestBooksByGenre" :key="book.id">
                <div class="image-container" @click="goToBook(book._id)">
                    <h3 style="margin-bottom: 5px;">{{ book.genre[0] }}</h3>
                    <img class="image-space" :src="book.imagePath" alt="Book Image">
                    <div class="text-overlay">
                        <div class="liked-percent">
                            <svg style="margin-right: 4px;" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" fill="white" height="15px" width="15px"
                                version="1.1" id="Capa_1" viewBox="0 0 51.997 51.997" xml:space="preserve">
                                <path
                                    d="M51.911,16.242C51.152,7.888,45.239,1.827,37.839,1.827c-4.93,0-9.444,2.653-11.984,6.905  c-2.517-4.307-6.846-6.906-11.697-6.906c-7.399,0-13.313,6.061-14.071,14.415c-0.06,0.369-0.306,2.311,0.442,5.478  c1.078,4.568,3.568,8.723,7.199,12.013l18.115,16.439l18.426-16.438c3.631-3.291,6.121-7.445,7.199-12.014  C52.216,18.553,51.97,16.611,51.911,16.242z" />
                            </svg>
                            <h5>{{ book.likedPercent }} %</h5>
                        </div>
                        <div style="display: flex; text-align: left;">
                            <h4>{{ book.title }}</h4>
                        </div>
                    </div>
                </div>
            </Slide>
            <template #addons>
                <Navigation />
            </template>
        </Carousel>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
export default {
    props: {
        BestBooksByGenre: {
            type: Array,
            required: true
        },
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
    },
    data() {
        return {
            itemsToShow: 6,
        }
    },
    mounted() {
        window.addEventListener('resize', this.checkScreenSize);
        this.checkScreenSize();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateScreenWidth);
        this.exported = false;
    },
    methods: {

        goToBook(bookId) {
            this.$router.push(`/book/${bookId}`);;
        },

        checkScreenSize() {
            if (window.innerWidth >= 1280) {
                this.itemsToShow = 1280 / 200;
            }
            else {
                this.itemsToShow = window.innerWidth / 200;
            }
        },
    }
}
</script>

<style scoped>
.image-container {
    text-decoration: none;
    color: white;
}

.image-container:hover {
    cursor: pointer;
}

.image-space {
    width: 150px;
    height: 230px;
    border-radius: 8px;
}

.text-overlay {
    align-items: left;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: none;
    padding: 5px;
    color: white;
    max-width: 150px;
}

.liked-percent {
    display: flex;
    align-items: left;
}
</style>