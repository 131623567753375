<template>
  <div style="margin-top: 70px;">
    <div class="about-place">
      <div class="content">
        <div style="display: flex;">
          <img src="../Images/edit.svg" @click="this.$router.push('/editbook/' + this.$route.params.id)" style="margin-left: auto; background-color: #292929; cursor: pointer;"  v-if="user.role == 'Admin'">
        </div>
        

        <div v-if="phoneDisplay">
          <BookAboutPhone :book="book"></BookAboutPhone>
        </div>
        <div v-else>
          <BookAboutDesktop :book="book"></BookAboutDesktop>
        </div>
      </div>
    </div>
    <div class="chapters">
      <div class="content">
        <BookChaptersDesktop :chapters="chapters.chapter" :user="user" v-if="isChapters"></BookChaptersDesktop>
        <div style="display: flex; justify-content: center;" v-else>
          <h2>Глави не знайдені</h2>
        </div>
      </div>
      <div class="addChaprt">
      </div>
    </div>
    <div>
      <button v-if="user.role == 'Admin'" class="button" @click="addChapterButton()">Додати розділ</button>
    </div>
    <div class="comments">
      <div class="content">
        <BookCommentsAdd v-if="user != ''" style="margin-top: 50px;"></BookCommentsAdd>
        <BookComments :comments="comments" v-if="isComments" style="margin-top: 40px;"></BookComments>
        <div style="display: flex; justify-content: center;" v-else>
          <h2 style="margin-top: 50px;">Коментарів немає</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import backendURL from '@/config';
import axios from 'axios';
import BookAboutDesktop from '../Book-about-desktop.vue';
import BookAboutPhone from '../Book-about-phone.vue';
import BookChaptersDesktop from '@/Components/Book-chapters-desktop.vue';
import BookComments from '../Book-comments.vue';
import BookCommentsAdd from '../Book-comments-add.vue';
import router from '../Router/router';

export default {
  data() {
    return {
      chapters: '',
      book: '',
      comments: '',
      user: '',
      phoneDisplay: false,
      isChapters: false,
      isComments: false
    }
  },
  components: {
    BookAboutDesktop, BookAboutPhone, BookChaptersDesktop, BookComments, BookCommentsAdd
  },
  watch: {
    $route(to, from) {
      if (to.params.id !== from.params.id) {
        this.$router.go(0);
      }
    }
  },
  mounted() {
    this.GetBook();
    window.addEventListener('resize', this.checkScreenSize);
    this.checkScreenSize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize);
    this.phoneDisplay = false;
  },
  methods: {
    addChapterButton() {
      this.$router.push('/book/' + this.$route.params.id + '/createchapter')
    },
    checkScreenSize() {
      if (window.innerWidth >= 800) {
        this.phoneDisplay = false;
      }
      else {
        this.phoneDisplay = true;
      }
    },
    async GetBook() {
      try {
        if (!this.book || Object.keys(this.book).length === 0 || this.book == '') {
          var getBookPath = backendURL + 'Book/GetBookById?bookId=' + this.$route.params.id;
          var bookResponse = await axios.get(getBookPath);
          this.book = bookResponse.data.result;
          
          var getChaptersPath = backendURL + 'Book/GetChaptersById?bookId=' + this.$route.params.id;
          var chaptersResponse = await axios.get(getChaptersPath);
          this.chapters = chaptersResponse.data.result;

          var getCommentsPath = backendURL + 'Book/GetCommentsByBookId?bookId=' + this.$route.params.id;
          var commentsResponse = await axios.get(getCommentsPath);
          console.log(this.commentsResponse);
          this.comments = commentsResponse.data;
          console.log(this.comments);

          var token = this.$cookies.get('token');
          if (token) {
            var getUserPath = backendURL + 'User/GetUserByToken';
            var userResponse = await axios.get(getUserPath);
            this.user = userResponse.data.result;
          }

          if (this.chapters != null) {
            this.isChapters = true;
          }

          if (this.comments != null) {
            this.comments = this.comments.filter(item => item.text !== "");
            this.isComments = true;
          }
        }
      } catch (error) {
        console.error('Помилка при завантаженні даних: ', error);
      }
    }
  }
}
</script>

<style scoped>
.button {
  background-color: #292929;
  border: 1px solid white;
  border-radius: 20px;
  color: white;
  width: 100%;
  height: 100px;
  margin-bottom: 100px;
}

.about-place {
  display: flex;
  justify-content: center;
  background-color: #292929;
  width: 100%;
  min-height: 200px;
  box-shadow: 0 20px 16px -16px rgba(0, 0, 0, 0.6);
}

.chapters {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 200px;
  padding: 50px 20px 50px 20px;

}

.comments {
  display: flex;
  justify-content: center;
  background-color: #292929;
  width: 100%;
  min-height: 200px;
  box-shadow: 0 -20px 16px -16px rgba(0, 0, 0, 0.6);
}

.content {
  background-color: var(--background-color);
  max-width: 1280px;
  width: 100%;
  padding: 20px 10px 20px 10px;
}

.content h1,
h2,
h3,
div {
  background-color: var(--background-color);
  color: white;
}
</style>