<template>
    <div>
        <div class="nav-search">
            <div class="search-box">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                    class="search-svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M13 8A5 5 0 1 1 3 8a5 5 0 0 1 10 0Zm-.808 5.606a7 7 0 1 1 1.414-1.414l5.101 5.1-1.414 1.415-5.1-5.1Z">
                    </path>
                </svg>
                <input v-model="searchText" type="text" class="search-place" placeholder="Пошук" @blur="ClearText()">
            </div>
        </div>
        <div v-if="this.results != ''" class="search-result">
            <div style="color: white;" v-for="item in results">
                <h4 class="search-box-item" @mousedown="GoToBook(item._id)">{{ item.title }}</h4>
            </div>
        </div>
    </div>
</template>

<script>
import backendURL from '@/config';
import axios from 'axios';

export default {
    data() {
        return {
            searchText: '',
            results: ''
        }
    },
    watch: {
        async searchText() {
            if (this.searchText.length >= 2) {
                this.results = (await axios.get(backendURL + 'Book/SearchBookByName?bookName=' + this.searchText)).data;
            }
            else {
                this.results = '';
            }
        }
    },
    methods: {
        GoToBook(bookID) {
            this.$router.push('/book/' + bookID);
            this.results = '';
            this.searchText = ''
        },
        ClearText(){
                this.searchText = '';
                this.results = '';
        }
    }
}
</script>

<style scoped>
.nav-search {
    background-color: #111111;
}

.nav-search div,
h1,
h2,
h3,
h4 {
    background-color: var(--background-color);
    color: white;
}

.search-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px 20px 20px 20px;
    padding: 0px 15px 0px 15px;
    border: 1px solid #303030;
    --background-color: #181818;
}

.search-place {
    border: 0px;
    width: 80px;
    height: 35px;
    padding: 10px;
    font-size: 16px;
    transition: all 0.2s ease;
}

.search-place:focus {
    outline: none;
    border: 0px;
    width: 200px;
}

.search-svg {
    height: 35px;
    color: white;
}

.search-result {
    position: fixed;
    width: 255px;
    height: auto;
    background-color: #111111;
    top: 60px
}
.search-box-item{
    border-bottom:2px solid #303030;
    padding: 3px;
}
.search-box-item:hover{
    background-color: #303030;
}
</style>