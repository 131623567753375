<template>
    <div class="advc-info-place">
        <h2 style="margin-bottom: 10px;">Для пополнения с помощю системи AdvCash</h2>
        <h3 style="margin-bottom: 10px;">Переведите средства на кошелек</h3>
        <div style="display: flex; gap: 10px; justify-content: center; background-color: black;">
            <h1 style="margin-bottom: 10px;">R 3950 5511 1020</h1>
            <svg @click="copyToClipboard()" style="background-color: black;" xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 448 512" :fill="fillColor"><path d="M208 0H332.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48zM48 128h80v64H64V448H256V416h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48z"/></svg>
        </div>
        <h3 style="color: brown;">Важно! В поле "Примечания" укажите логин на сайте</h3>
        <h4 style="margin-top: 10px;">Зачесление на баланс сайта поступает в течении одного робочего дня</h4>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isGreen: false,
            clipboard: 'R 3950 5511 1020',
            fillColor: 'white'
        }
    },
    methods: {
        copyToClipboard() {
            const textField = document.createElement('textarea');
            textField.value = this.clipboard;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            document.body.removeChild(textField);
            console.log('Текст скопійовано в буфер обміну');
            this.fillColor ='green'
        },
    }

}
</script>

<style scoped>
.advc-info-place {
    background-color: black;
    color: white;
    padding: 20px;
    border-radius: 20px;
}

.advc-info-place h3,
h2,
h4,
h1 {
    background-color: black;
}</style>