<template>
    <div class="add-to-b-place">
        <input class="inputs" v-model="login" type="text" placeholder="Логин">
        <input class="inputs" v-model="value" type="number" placeholder="Сумма зачисления">
        <button class="complete-button" @click="addToBalance()">Нарахувати</button>
    </div>
</template>

<script>
import backendURL from '@/config';
import axios from 'axios';

export default {
    data() {
        return {
            login: '',
            value: 0,
        }
    },
    methods: {
        async addToBalance() {
            try {
                var result = await axios.post(backendURL + 'User/AddToBalance?login=' + this.login + '&value=' + this.value);
                alert(result.data);
            } catch (error) {
                alert(error.response.data);
            }
        }
    }
}
</script>

<style scoped>
.add-to-b-place {
    background-color: black;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-flow: column;
    gap: 10px;
}

.inputs {
    width: 300px;
    padding: 5px;
    background-color: #181818;
    border: 1px solid gray;
    border-radius: 20px;
}

.complete-button {
    background-color: #181818;
    color: white;
    border-radius: 20px;
    padding: 10px;
    border: 1px solid gray;

}
</style>