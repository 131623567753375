<template>
    <div style="margin-top:100px; display: flex; justify-content: center;">
        <div style="max-width: 1280px; width: 100%;">
            <h2 style="margin-bottom: 20px;">Статистика по книжкам</h2>
            <div v-if="isLoaded">
                <div v-for="item in bookStatistic" class="OneBookStatistic" style="margin-bottom: 20px;">
                    <h2 style="background-color: #111111; margin-bottom: 10px;">{{ FindBook(item._id).title }}</h2>
                    <div style="background-color: #111111; display: flex; gap:15px">
                        <h3 style="background-color: #111111;">Переглядів: {{ FindBook(item._id).views }}</h3>
                        <h3 style="background-color: #111111;">Всього придбань глав: {{ item.totalBuyCounter }}</h3>
                        <h3 style="background-color: #111111;">Отримано грошей з придбань: {{ item.totalEarnings }}</h3>
                        <svg @click="item.showChapters = !item.showChapters"
                            style="margin-left: auto; background-color: #111111;" width="20" height="20" viewBox="0 0 20 20"
                            fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                            class="origin-center rotate-180 text-gray-600">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="m10 7.37 7.316 6.86 1.368-1.46L10 4.63l-8.684 8.14 1.368 1.46L10 7.37Z"></path>
                        </svg>
                    </div>
                    <div v-show="item.showChapters" v-for="chapter in item.chapters" style="margin-top: 15px;">
                        <div style="background-color: #111111; border-top: 1px solid white;">
                            <h3 style="background-color: #111; padding: 10px; margin-top: 10px;">{{ chapter.title }} </h3>
                        </div>
                        <div style="display: flex; gap:10px; background-color: #111;">
                            <h3 style="background-color: #111; margin-left: 10px;">
                                Прочитано раз : {{ FindChapterStatistic(item, chapter.chapterId) == null ? '0' :  FindChapterStatistic(item, chapter.chapterId).readCounter }}
                            </h3>
                            <h3 style="background-color: #111;">
                                Куплено раз : {{ FindChapterStatistic(item, chapter.chapterId) == null ? '0' :  FindChapterStatistic(item, chapter.chapterId).buyCounter }}
                            </h3>
                            <h3 style="margin-bottom: 5px; background-color: #111;">
                                Отримано грошей з придбань : {{ FindChapterStatistic(item, chapter.chapterId) == null ? '0' :  FindChapterStatistic(item, chapter.chapterId).earnings }}
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import backendURL from '@/config';
import axios from 'axios';

export default {
    data() {
        return {
            bookStatistic: [],
            book: [],
            chapters: [],
            isLoaded: false,
        }
    },
    async beforeRouteEnter(to, from, next) {
        const token = $cookies.get('token');
        if (token) {
            const response = await axios.get(backendURL + 'User/GetUserByToken');
            if (response.data.result.role == 'Admin') {
                next();
            }
            else {
                next({ path: '/' });
            }
        }
        else {
            next({ path: '/' });
        }
    },
    mounted() {
        this.GetStatistic()
    },
    methods: {
        async GetStatistic() {
            var bookStatisticResult = await axios.get(backendURL + 'AdminBook/GetBookStatisticList');
            this.bookStatistic = bookStatisticResult.data;
            this.bookStatistic.forEach(async item => {
                try {
                    var chapters = await axios.get(backendURL + 'Book/GetChaptersById?bookId=' + item._id)
                    item.chapters = chapters.data.result.chapter
                }
                catch {
                    item.chapters = null;
                }
                item.showChapters = false;
            });
            var bookResult = await axios.get(backendURL + 'adminbook/GetAllBooks');
            this.book = bookResult.data;
            this.isLoaded = true;
        },
        FindBook(id) {
            var result = this.book.find(b => b._id == id)
            return result
        },
        FindChapterStatistic(item, chapterId){
            try{
                return item.chaptersStatistic.find(i => i.chapterId == chapterId)
            }
            catch{
                return 0;
            }
        }

    }

}
</script>

<style scoped>
.OneBookStatistic {
    border-radius: 20px;
    padding: 20px;
    color: white;
    background-color: #111111;
}
</style>