<template>
    <div style="margin-top: 70px;">
        <div style="display: flex; justify-content: center; width: 100%;">
            <div class="content-block">
                <input style="margin-bottom: 10px;" v-model="this.chapter.title" class="input-text" type="text"
                    placeholder="Название главы" />
                <div style="display: flex; align-items: center; margin-bottom: 40px; margin-left: 10px;">
                    <h2>
                        Це платний розділ?
                    </h2>
                    <input type="checkbox" v-model="chapter.hasPrice" id="switch" style="margin-right: 10px;" /><label
                        for="switch">Toggle</label>
                    <input class="input-price" v-if="chapter.hasPrice" v-model="chapter.price" type="number"
                        style="margin-left: 10px;" placeholder="Вартисть розділу">
                </div>
                <div class="text-editor">
                    <Editor v-model="chapter.text" api-key="rkvup53v0v6j6yet02n4ggc5ye3gt5dgav048hi49i1em1mx" />
                </div>
                <button
                    style="color: white; border:1px solid white; border-radius: 20px; width: 100%; height: 100px; margin-top: 20px;"
                    @click="addChapter()">Створити</button>
                <div class="preview">
                    <h1 style="text-align: center; margin-bottom: 50px; margin-top: 50px;">Передогляд</h1>
                    <div style="color: white;" v-html="chapter.text"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import backendURL from '@/config';
import Editor from '@tinymce/tinymce-vue'
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import axios from 'axios';
export default {
    data() {
        return {
            chapter: {
                chapterId: '',
                title: '',
                text: '',
                price: 0,
                hasPrice: false
            },
        }
    },
    components: {
        Editor
    },
    methods: {
        async addChapter() {
            try {
                this.chapter.chapterId = '10';
                var result = await axios.post(backendURL + 'AdminBook/CreateChapter?bookId=' + this.$route.params.id, this.chapter, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                console.log(result);
                if (result.data.result == true) {
                    this.$router.push('/book/' + this.$route.params.id)
                }
            }
            catch (error) {
                alert(error);
            }
        }
    },
    async beforeRouteEnter(to, from, next) {
        const token = $cookies.get('token');
        if (token) {
            const response = await axios.get(backendURL + 'User/GetUserByToken');
            if (response.data.result.role == 'Admin') {
                next();
            }
            else {
                next({ path: '/' });
            }
        }
        else {
            next({ path: '/' });
        }
    }
}
</script>

<style>
.content-block {
    max-width: 1280px;
    width: 100%;
    display: flex;
    width: 100%;
    flex-flow: column;
    margin-top: 30px;
}

.content div,
h1,
h2,
h3,
h4,
input,
textarea {
    color: white;
}

.input-text {
    background-color: #111111;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid white;
    height: 50px;
    font-size: 20px;
}

input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}

label {
    cursor: pointer;
    text-indent: -9999px;
    width: 50px;
    height: 25px;
    background: red;
    display: block;
    border-radius: 100px;
    position: relative;
}

label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 3px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

input:checked+label {
    background: #bada55;
}

input:checked+label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

label:active:after {
    width: 25px;
}

.input-price {
    border-radius: 20px;
    padding: 10px;
    width: 200px;
    height: 30px;
    border: 1px solid white;
}

.placeholder-text {
    background-color: #111111;
    border-radius: 20px;
    height: 500px;
    padding: 10px;
    width: 100%;
    font-size: 20px;
    color: white
}

.ql-editor p {
    color: white;
}

.text-editor svg {
    background-color: #fff;
}
</style>