<template>
    <div style="display: flex; width: 100%;">
        <div v-if="isLoaded" style="display: flex; justify-content: center; width: 100%;">
            <Carousel :model-value="this.slideCounter" :snap-align="'start'" :pause-autoplay-on-hover="true"
                :items-to-show="1" :autoplay="5000">
                <Slide v-for="p in poster" :key="p._id">
                    <div @click="GoToBook(p._id)" class="image-container">
                        <img class="image" :src="p.image" alt="PosterPhoto">
                        <div class="background"></div>
                        <div class="overlay-text">
                            <h4>{{ book.find(b => b._id == p._id).title }}</h4>
                            <div
                                style="text-align: left; background-color: rgba(0,0,0,0);display: flex; gap:5px; margin-top: 10px;">
                                <img style="background-color: rgba(0,0,0,0);" src="./Images/heart.svg" alt="Heart">
                                <h4 style="">{{ book.find(b => b._id == p._id).likedPercent }}%</h4>
                            </div>
                            <h4 style="margin-top: 10px;">{{ book.find(b => b._id == p._id).description.slice(0, 250) }}...
                            </h4>
                        </div>
                    </div>
                </Slide>
                <template #addons>
                    <Navigation />
                    <Pagination />
                </template>
            </Carousel>
        </div>
        <div v-else style="display: flex; justify-content: center; width: 100%; margin-bottom: 25px;">
            <div class="skeleton-load">
                <div class="skeleton-element"></div>
            </div>
        </div>
    </div>
</template>

<script>
import backendURL from '@/config';
import axios from 'axios';
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'


export default {
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    data() {
        return {
            poster: [],
            book: [],
            isLoaded: false,
            slideCounter: ''
        }
    },
    mounted() {
        this.GetPosters();
    },
    methods: {
        async GetPosters() {
            var posterResult = await axios.get(backendURL + 'poster/getposterlist');
            this.poster = posterResult.data;
            for (const element of this.poster) {
                var bookResult = await axios.get(backendURL + "book/GetBookById?bookId=" + element._id);
                this.book.push(bookResult.data.result);
            }
            this.isLoaded = true;
        },
        GoToBook(id) {
            this.$router.push('/book/' + id)
        }
    }
}
</script>

<style scoped>
.image {
    border-radius: 20px;
    /* max-width: 600px; */
    /* max-height: 1280px; */
    width: 100%;
    height: 100%;
}

.image-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.overlay-text {
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    /* max-width: 600px; */
    max-height: 220px;
    width: 100%;
    height: 100%;
    bottom: 0px;
    padding: 10px;
}

.overlay-text h4 {
    background-color: rgba(0, 0, 0, 0);
    text-align: left;
}

.background {
    position: absolute;
    height: 220px;
    width: 100%;
    max-width: 600px;
    align-items: center;
    bottom: 4px;
    border-radius: 0px 0px 20px 20px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));

}

.skeleton-load {
    display: flex;
    justify-content: center;
    max-width: 600px;
    max-height: 337px;
    width: 100%;
    height: 100%;
}

.skeleton-element {
    border-radius: 20px;
    width: 100%;
    padding-top: 56.16%;
    /* 337 / 600 * 100 */
    margin-bottom: 22px;
    background-image: linear-gradient(90deg, #111111, #515151, #111111);
    background-size: 200% 100%;
    animation: gradientAnimation 10s linear infinite;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.carousel__pagination-button {
    fill: white;
}
</style>
