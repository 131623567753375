<template>
    <div>
        <div class="menus-container">
            <div class="menus-panel">
                <div class="change-button" @click="toggleMenu">
                    <svg width="22" height="20" xmlns="http://www.w3.org/2000/svg" class="h-[20px] w-[22px] fill-current">
                        <g fill="none" fill-rule="evenodd">
                            <path d="M6 4.824V2h15v2.824M10.688 17.059h5.625M13.5 2v15.059" stroke="white"
                                stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
                            <path fill="white" d="M0 9.834V8h10v1.834H6.183v9.182H4.009V9.834z"></path>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
        <div style="display: flex; width: 100%; justify-content: center;">
            <div class="cont">
                <div class="menu" :class="{ 'open': isMenuOpen }">
                    <div class="font-family-switcher">
                        <div class="font-switch-button" @click="switchFamily(1)">
                            <h4>Times new roman</h4>
                        </div>
                        <div class="font-switch-button" @click="switchFamily(2)">
                            <h4>Open Sans</h4>
                        </div>
                    </div>
                    <div class="font-family-switcher">
                        <div class="font-switch-button" @click="switchFamily(3)">
                            <h4>Montserrat</h4>
                        </div>
                        <div class="font-switch-button" @click="switchFamily(4)">
                            <h4>Lato</h4>
                        </div>
                    </div>
                    <div class="slide-container">
                        <h3 style=" margin-right: 30px; margin-left:20px;">Розмір </h3>
                        <input type="range" min=8 max=40 class="slider" v-model="params.fontSize">
                        <h3 style="margin-left: 6px;">{{ params.fontSize }} px.</h3>
                    </div>
                    <div class="slide-container">
                        <h3 style="margin-left: 20px ;margin-right: 6px;">Інтервал </h3>
                        <input type="range" min=10 max=40 class="slider" v-model="params.lineHeight">
                        <h3 style="margin-left: 6px;">{{ params.lineHeight }} px.</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            params: {
                fontSize: 18,
                lineHeight: 25,
                fontFamily: "'Montserrat', sans-serif",
            },
            isMenuOpen: false
        }
    },
    watch: {
        params: {
            handler(newValue) {
                this.$emit('changeFontSize', newValue);
            },
            deep: true
        }
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        switchFamily(number){
            switch (number){
                case 1:
                this.params.fontFamily = "'Times New Roman', Times, serif";
                break;
                case 2:
                this.params.fontFamily = "'Open Sans', sans-serif";
                break;
                case 3:
                this.params.fontFamily = "'Montserrat', sans-serif";
                break;
                case 4:
                this.params.fontFamily = "'Lato', sans-serif";
                break;
            }
        }
    }
}
</script>

<style scoped>
.menus-container {
    background-color: #111111;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.menus-container div,
h2,
h3,
h4 input,
svg {
    background-color: #111111;
}

.menus-panel {
    padding: 15px;
    display: flex;
    width: 100%;
    max-width: 1280px;
    height: 50px;
    align-items: center;
}

.change-button {
    font-family: 'Times New Roman', Times, serif;
    cursor: pointer;
}

.cont {
    max-width: 1280px;
    width: 100%;
}

.menu {
    background-color: #111111;
    position: fixed;
    bottom: 50px;
    border-radius: 20px;
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
}

.menu.open {
    height: 170px;
    padding: 15px;
}

.menu div,
h2,
h3,
h4,
input {
    background-color: var(--background-color);
}

.menu a {
    display: block;
    padding: 10px;
    color: white;
    text-decoration: none;
}

menu h2,
h3 {
    color: white;
}

.menu a:hover {
    background-color: #333333;
}

.slide-container {
    display: flex;
}

.font-switch-button {
    --background-color: #333333;
    width: 150px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin: 5px;
    transition: all 0.2s;
}

.font-switch-button h4 {
    color: white;
}

.font-switch-button:hover {
    --background-color: #111111;
    cursor: pointer;
}

.font-family-switcher {
    display: flex;
}
</style>