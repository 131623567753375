<template>
    <div>
        <div style="display: flex; justify-content: center;">
            <img class="image-space-phone" :src="book.imagePath" alt="Book Image">
        </div>
        <div style="margin-top: 20px;">
            <h2>{{ book.title }}</h2>
        </div>
        <div class="liked-percent">
            <svg style="margin-right: 4px; background-color: var(--background-color);" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" fill="white" height="20px" width="20px" version="1.1" id="Capa_1"
                viewBox="0 0 51.997 51.997" xml:space="preserve">
                <path
                    d="M51.911,16.242C51.152,7.888,45.239,1.827,37.839,1.827c-4.93,0-9.444,2.653-11.984,6.905  c-2.517-4.307-6.846-6.906-11.697-6.906c-7.399,0-13.313,6.061-14.071,14.415c-0.06,0.369-0.306,2.311,0.442,5.478  c1.078,4.568,3.568,8.723,7.199,12.013l18.115,16.439l18.426-16.438c3.631-3.291,6.121-7.445,7.199-12.014  C52.216,18.553,51.97,16.611,51.911,16.242z" />
            </svg>
            <h3 style="background-color: var(--background-color);">{{ book.likedPercent }} %</h3>
            <svg style="margin-left: 15px; background-color: var(--background-color);" xmlns="http://www.w3.org/2000/svg"
                version="1.0" width="21pt" height="21pt" viewBox="0 0 1280.000000 662.000000"
                preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,662.000000) scale(0.100000,-0.100000)" fill="#ffffff" stroke="none">
                    <path
                        d="M6330 6610 c-1399 -91 -2792 -594 -4189 -1515 -694 -457 -1415 -1050 -1957 -1609 l-183 -189 100 -108 c140 -151 583 -569 839 -794 1446 -1267 2965 -2053 4445 -2299 423 -70 660 -90 1105 -90 383 -1 517 7 845 49 1006 129 1985 482 2960 1068 876 526 1767 1287 2429 2075 l78 93 -19 22 c-11 12 -75 87 -144 167 -1111 1299 -2373 2239 -3644 2718 -576 216 -1111 340 -1725 398 -195 18 -747 26 -940 14z m421 -580 c562 -56 1096 -275 1534 -627 306 -246 561 -564 734 -916 91 -184 137 -304 187 -486 136 -496 123 -1033 -37 -1521 -81 -246 -179 -448 -324 -665 -109 -163 -193 -264 -349 -420 -232 -232 -450 -387 -751 -535 -280 -138 -550 -222 -875 -271 -196 -30 -580 -33 -775 -5 -680 94 -1246 378 -1705 852 -422 437 -671 963 -746 1574 -20 166 -15 517 11 680 159 1029 879 1869 1890 2205 218 72 403 111 655 138 80 9 455 6 551 -3z" />
                    <path
                        d="M6330 5359 c-375 -31 -742 -175 -1035 -404 -87 -68 -237 -217 -308 -306 -110 -136 -228 -347 -286 -512 -79 -225 -106 -402 -98 -657 7 -242 36 -385 119 -595 277 -703 983 -1174 1760 -1175 434 0 863 146 1203 412 80 62 242 226 310 313 182 232 307 512 359 804 l6 34 -42 -40 c-142 -130 -319 -224 -510 -270 -56 -14 -114 -18 -248 -18 -159 0 -184 3 -275 28 -381 104 -674 395 -767 763 -32 125 -32 371 0 486 51 185 144 348 274 478 90 90 171 148 285 204 140 69 261 101 426 113 l89 6 -68 44 c-347 219 -785 327 -1194 292z" />
                </g>
            </svg>
            <h3 style="margin-left: 4px;">{{ book.views }}</h3>
        </div>
        <h3 style="margin-top: 4px;">Автор: {{ book.author }}</h3>
        <div style="margin-top: 10px; display: flex;">
                <h3>Жанр:</h3>
                <div style="display: flex; flex-wrap: wrap; gap:5px;">
                    <div v-for="genre in book.genre" class="genresbox">
                        <h3 style="margin-left: 7px;"> {{ genre }}</h3>
                    </div>
                </div>
            </div>
        <div style="margin-top: 20px;">
            <div v-if="showFullDesc">
                <h3>{{ book.description }}</h3>
            </div>
            <div v-else>
                <h3>{{ shortDesc }}</h3>
            </div>
            <button class="show-more-button" @click="toggleDescription">
                {{ showFullDesc ? 'Сховати' : 'Показати більше' }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            shortDesc: '',
            showFullDesc: false
        }
    },
    props: {
        book: {
            required: true,
        }
    },
    mounted() {
    },
    methods: {
        sliceDesc() {
            this.shortDesc = this.book.description.slice(0, 200) + '...';
        },

        toggleDescription() {
            this.sliceDesc();
            this.showFullDesc = !this.showFullDesc;
        },
    }
}

</script>

<style scoped>
.content h1,
h2,
h3,
div {
    background-color: var(--background-color);
    color: white;
}

.liked-percent {

    margin-top: 6px;
    background-color: var(--background-color);
    display: flex;
    align-items: center;
    color: white;
}
.genresbox{
    background: #111111;
    border-radius: 10px; padding: 0px 12px 0px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}
.image-space-phone {
    width: 150px;
    border-radius: 20px;
}

.show-more-button {
    width: 100%;
    height: 30px;
    background-color: #111111;
    border-radius: 20px;
    border: 0;
    color: white;
    margin-top: 6px;
}
</style>