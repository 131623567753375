<template>
    <div style="margin-top: 40px; display: flex; align-items: center; flex-flow: column;">
        <div class="content">
            <div class="photo-about">
                <div class="photo">
                    <h3>Необхідно обрати фото</h3>
                    <h4>Рекомендований розмір </h4>
                    <h4 style="margin-bottom: 20px;">Зображення 9:16</h4>
                    <input type="file" @change="handleFileChange" />
                </div>
                <div class="about">
                    <input class="input-string" type="text" v-model="book.title" placeholder="Назва книги">
                    <input style="margin-top: 20px;" class="input-string" type="text" v-model="book.author"
                        placeholder="Автор книги">
                    <div style="margin-top: 20px;" class="choise-genre">
                        <h3 style="margin-right: 10px; margin-left: 7px;">Виберіть жанри:</h3>
                        <div v-for="item in genres" style="margin-top: 7px; margin-left: 7px;" :key="item">
                            <div class="genre-button" :class="{ active: isSelected(item) }" @click="toggleGenre(item)">
                                {{ item }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="margin-top: 10px;" class="content">
            <div class="choise-genre">
                <h3>Виберіть мову оригіналу:</h3>
                <div v-for="item in languages" :key="item.id" style="margin-top: 7px; margin-left: 7px;">
                    <div>
                        <div :class="{ 'active': book.originalLanguage == item }" @click="selectLanguage(item)"
                            class="genre-button"> {{ item }}</div>
                    </div>
                </div>
            </div>
            <textarea v-model="book.description" style="margin-top: 20px;" class="description"
                placeholder="Введіть опис"></textarea>
            <button
                style="border: 1px solid white; border-radius: 20px; margin-top: 20px; color:white; width: 100%; height: 100px;"
                @click="createBook()">Створити книжку</button>
        </div>
    </div>
</template>

<script>
import backendURL from '@/config';
import axios from 'axios';
export default {
    data() {
        return {
            book: {
                title: '',
                description: '',
                author: '',
                originalLanguage: '',
                genre: [],
                image: null,
            },
            genres: ["Бойовик", "Бойові Мистецтва", "Гарем", "Героїчне Фентезі", "міське Фентезі",
            "Джьосей", "Драма", "Історія", "Кодомо", "Комедія", "Кіберпанк", "ЛітRPG",
            "Містика", "Наукова фантастика", "Повсякденність", "Постапокаліпсис", "Пригоди",
            "Психологія", "Романтика", "Надприродне", "Сьодзьо", "Сьонен", "Спорт", "Сейнен",
            "Трагедія", "Трилер", "Жахи", "Фантастика", "Фентезі", "Школа", "Еротика", "Етті", "Юрі", "Яой"],
            languages: ["Англійська", "Китайська", "Корейська", "Японська"]
        }
    },
    methods: {
        async createBook() {
            try {
                const result = await axios.post(
                    backendURL + 'adminbook/createnewbook',
                    this.book,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                if (result.data.result == true) {
                    this.$router.push('/')
                }
            } catch (error) {
                alert(error);
                console.error('Error creating book:', error);
            }
        },
        handleFileChange(event) {
            const file = event.target.files[0];
            this.book.image = file;
        },
        selectLanguage(language) {
            this.book.originalLanguage = language;
        },
        isSelected(item) {
            return this.book.genre.includes(item);
        },
        toggleGenre(item) {
            if (this.book.genre.includes(item)) {
                this.book.genre = this.book.genre.filter(genre => genre !== item);
            } else {
                this.book.genre.push(item);
            }
        }

    },
    async beforeRouteEnter(to, from, next) {
        const token = $cookies.get('token');
        if (token) {
            const response = await axios.get(backendURL + 'User/GetUserByToken');
            if (response.data.result.role == 'Admin') {
                next();
            }
            else {
                next({ path: '/' });
            }
        }
        else {
            next({ path: '/' });
        }
    }
}
</script>

<style scoped>
.content {
    width: 100%;
    max-width: 1280px;
}

.content h1,
h2, 
h3,
h4,
div,
input {
    background-color: var(--background-color);
    color: white;
}

.photo-about {
    display: flex;
    margin-top: 40px
}

.photo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    margin-right: 50px;
    width: 400px;
    height: 500px;
    background-color: #111111;
    border-radius: 20px;
}

.about {
    width: 100%;
}

.input-string {
    width: 100%;
    height: 45px;
    border-radius: 20px;
    padding: 10px;
    border: 1px solid gray;
    color: white;
    font-size: 18px;
}

.choise-genre {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.genre-button {
    display: flex;
    align-items: center;
    height: 20px;
    width: auto;
    padding: 17px;
    background-color: #111111;
    border-radius: 5px;
    transition: all 0.2s ease;
}

.genre-button:hover {
    cursor: pointer;
    background-color: #1070b0;
}

.active {
    background-color: #3490dc;
}

.description {
    width: 100%;
    height: 300px;
    border-radius: 20px;
    padding: 10px;
    color: white;
    font-size: 15px;
}
</style>