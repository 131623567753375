<template>
  <div>
    <div v-if="!paidFor" style="display: flex; align-items: center;">
      <h2>Сумма:</h2>
      <input style="margin-left: 15px; margin-right: 10px;" type="number" class="input-sum" v-model="product.price">
      <h2>USD</h2>
    </div>

    <div v-if="paidFor">
      <h1>Успешное пополнение!</h1>
    </div>

    <div v-else style="margin-top: 20px;" ref="paypal"></div>
  </div>
</template>
  
<script>
import backendURL from '@/config';
import axios from 'axios';
export default {

  data() {
    return {
      loaded: false,
      paidFor: false,
      product: {
        price: 5,
        description: '',
      }
    };
  },
  mounted() {
    axios.get(backendURL + 'user/GetUserByToken').then(response => {
      this.product.description = response.data.result.login;
    })
      .catch(error => {
        console.error(error);
      });
    const script = document.createElement("script");
    script.src =
      "https://www.paypal.com/sdk/js?client-id=AcwBaduZ-98UPAt5nzerp4R0YiZCgGzevlhZtEb8N9WyX8xo4U1-UF_VQS7tEeAd7Mxd6hvc4jc0v8Bv";
    script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);
  },
  methods: {
    setLoaded: function () {
      this.loaded = true;
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: this.product.description,
                  amount: {
                    currency_code: "RUB",
                    value: this.product.price
                  }
                }
              ]
            });
          },
          onApprove: async (data, actions) => {
            var result = await axios.get('http://localhost:5041/Paymant/PaypalPayment?order_id=' + data.orderID + '&token=' + data.facilitatorAccessToken);
            if (result.status == 200) {
              setTimeout(function () {
                this.$router.go('/')
              }, 3000);
            }
            this.paidFor = true;
          },
          onError: err => {
            console.log(err);
          }
        })
        .render(this.$refs.paypal);
    }
  }
};
</script>
<style scoped>
.input-sum {
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  border: 1px solid grey;
}
</style>
  