<template>
  <div style="display: flex; flex-direction:row; align-items: center;">
    <div style="flex-grow: 1; flex-basis: 0;" v-for="item in totalItems" :key="item">
      <div class="loading-element"></div>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      itemsToShow: 6,
    };
  },
  beforeMount() {
    window.addEventListener('resize', this.checkScreenSize);
    this.checkScreenSize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  computed: {
    totalItems() {
      return Math.ceil(this.itemsToShow); 
    },
  },
  methods: {
    checkScreenSize() {
      if (window.innerWidth >= 1280) {
        this.itemsToShow = 1280 / 200;
      } else {
        this.itemsToShow = window.innerWidth / 220;
      }
    },
  },
};
</script>
  
<style scoped>
.loading-element {
  border-radius: 8px;
  width: 150px;
  height: 230px;
  background-image: linear-gradient(90deg, #111111, #515151, #111111);
  background-size: 200% 100%;
  animation: gradientAnimation 10s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}</style>
  