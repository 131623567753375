<template>
    <div style="display: flex;align-items: center;justify-content: center; margin-top: 15%;">
        <div class="content" style="gap:15px">
            <h2 style="margin-left: 10px;">Реєстарція</h2>
            <div class="login">
                <h4 v-if="loginLength" style="text-align: center; color: red;">Ім'я має бути не меньше за 3 символи</h4>
                <h4 v-if="isUserCreated" style="text-align: center; color: red;">Такий користувач вже існує</h4>
                <input v-model="request.login" class="login-input" type="text" placeholder="Ім'я користувача" required>
            </div>
            <div class="password">
                <h4 v-if="passlength" style="text-align: center; color: red;">Пароль має містити не меньше 8 символів</h4>
                
                <input v-model="request.password" class="login-input" type="password" placeholder="Пароль" required>
            </div>
            <div class="confirm-password">
                <h4 v-if="request.password != request.confirmPassword && request.confirmPassword != ''"
                    style="text-align: center; color: red;">Паролі не співпадають</h4>
                <input v-model="request.confirmPassword" class="login-input" type="password" placeholder="Повторіть пароль"
                    required>
            </div>
            <button @click="Register()" style="margin-top: 20px" class="login-button">Продовжити</button>
        </div>
    </div>
</template>

<script>
import backendURL from '@/config';
import axios from 'axios';

export default {
    data() {
        return {
            request: {
                login: '',
                password: '',
                confirmPassword: ''
            },
            loginLength: false,
            passlength: false,
            isUserCreated: false
        }
    },
    beforeRouteEnter(to, from, next) {
        const token = $cookies.get('token');
        if (token) {
            next({ path: '/' });
        } else {
            next();
        }
    },
    methods: {
        async Register() {
            if (this.request.login.length < 4) {
                this.loginLength = true;
            }
            else {
                this.loginLength = false;
            }
            if (this.request.password.length < 8) {
                this.passlength = true;
            }
            else {
                this.passlength = false;
            }

            if (this.request.password == this.request.confirmPassword && this.request.password.length >= 8 && this.request.login.length >= 4) {
                var result = await axios.post(backendURL + 'user/registration', this.request)
                console.log(result);
                if (result.data.statusCode == 400) {
                    this.isUserCreated = true;
                }
                else { this.isUserCreated = false; }
            }
            if(result.data.result != null){
                this.$cookies.set('token', result.data.result, '1d');
                this.$router.go('/')
            }
        }
    }
}
</script>

<style scoped>
.content {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    min-height: 300px;
    max-width: 800px;
    max-height: 800px;
    border-radius: 20px;
    margin: 10px;
}

.login-input {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    color: white;
    border: 2px solid #454545;
    text-decoration: none;
    background-repeat: no-repeat;
    padding: 5px 5px 5px 15px;
}

.login-button {
    border-radius: 20px;
    border: 2px solid #454545;
    width: 100%;
    height: 40px;
    color: white;
    background-color: #454545;
    transition: 0.3s ease-in-out;
}

.login-button:hover {
    background-color: #111111;
}
</style>