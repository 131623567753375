<template>
    <div class="chapters-place">
        <div class="show-more" @click="ShowMore">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                class="origin-center rotate-180 text-gray-600" :class="{ active: !showMore }">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="m10 7.37 7.316 6.86 1.368-1.46L10 4.63l-8.684 8.14 1.368 1.46L10 7.37Z"></path>
            </svg>
            <h2 style="margin-left: 20px;">{{ showMore ? 'Приховати розділи' : 'Показати всі розділи' }}</h2>
        </div>
        <div class="container-grid">
            <div v-for="(item, index) in chaptersToShow" :key="item.id">
                <div class="one-chapter">
                    <div v-if="user.role == 'Admin'">
                        <img src="../Components/Images/edit.svg" style="background-color: #333333;" alt="Змінити главу" @click="this.$router.push('/editbook/' + this.$route.params.id + '/editchapter/' + item.chapterId)">
                    </div>
                    <div v-if="!item.hasPrice" class="free-chapter" @click="ReadChapter(item.chapterId)">
                        <h3>{{ item.title }}</h3>
                        <div class="free-read">Читати</div>
                    </div>
                    <div v-else style="width: 100%;">
                        <div v-if="user != ''">
                            <div v-if="isChapterBought(item.chapterId)">
                                <div class="bought" @click="ReadChapter(item.chapterId)">
                                    <h3>{{ item.title }}</h3>
                                    <div class="bought-can-read">Придбано</div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="not-bought" @click="BuyChapter(this.$route.params.id, item.chapterId)">
                                    <h3>{{ item.title }}</h3>
                                    <div class="not-bought-cost">{{ item.price }} р.</div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <RouterLink to="/login" style="text-decoration: none;color: white; ">
                                <div class="not-bought">
                                    <h3>{{ item.title }}</h3>
                                    <div class="not-bought-cost">{{ item.price }} р.</div>
                                </div>
                            </RouterLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import backendURL from '@/config';
export default {

    data() {
        return {
            showMore: false,
            chaptersToShow: [],
            chaptersPerPage: 6,
            purchased: []
        };
    },
    props: {
        chapters: {
            required: true,
        },
        user: {
            required: true,
        }
    },
    watch: {
        chapters: {
            immediate: true,
            handler(newChapters, oldComments) {
                if (newChapters !== undefined) {
                    this.chaptersToShow = newChapters.slice(0, this.chaptersPerPage);
                } else {
                    console.error("Invalid chapters data:", newChapters);
                }
            },
        },
    },
    methods: {
        async BuyChapter(bookId, chapterId) {
            var result = confirm("Купить главу?");
            if (result) {
                const buyChapterPath = backendURL + 'User/BuyChapter';
                var buyChapterData = { bookId, chapterId };
                var buyResult = await axios.post(buyChapterPath, buyChapterData);
                if (buyResult.data.result) {
                    if (this.purchased) {
                        this.purchased.chapterID.push(chapterId);
                    }
                    else {
                        this.purchased = {
                            bookID: bookId,
                            chapterID: [chapterId]
                        }
                    }
                }
                else {
                    alert(buyResult.data.comment);
                }
            }
        },
        ShowMore() {
            this.showMore = !this.showMore;
            if (this.showMore) {
                this.chaptersToShow = this.chapters;
            } else {
                this.chaptersToShow = this.chapters.slice(0, this.chaptersPerPage);
            }
        },
        isChapterBought(chapterid) {
            return this.purchased && this.purchased.chapterID && this.purchased.chapterID.includes(chapterid);
        },
        ReadChapter(chapterId) {
            this.$router.push({
                name: 'Chapter',
                params: {
                    id: this.$route.params.id,
                    chapterId: chapterId
                }
            })
        }
    },
    mounted() {
        if (this.user != '') {
            if (this.user.purchased != null) {
                const foundElement = this.user.purchased.find(item => item.bookID == this.$route.params.id);
                if (foundElement == undefined) {
                    this.purchased = false;
                }
                else this.purchased = foundElement;
            }
            else {
                this.purchased = false;
            }
        }
    }
};
</script>


<style scoped>
.chapters-place {
    padding: 15px;
    background-color: #333333;
    width: 100%;
    height: auto;
    border-radius: 20px;
    transition: height 0.3s ease;
}

.chapters-place div,
h2,
h3,
svg {
    background-color: var(--background-color);
}

.show-more {
    color: white;
    display: flex;
    align-items: center;
    height: 100%;
}

.show-more:hover {
    cursor: pointer;
}

.rotate-180 {
    transform-origin: center;
    transition: transform 0.2s ease;
}

.rotate-180.active {
    transform: rotate(180deg);
}

.container-grid {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-column-gap: clamp(1rem, 1vw, 10rem);
}

@media screen and (min-width: 768px) {
    .container-grid {
        grid-template-columns: repeat(auto-fit, minmax(400px, 2fr));
    }
}

.one-chapter {
    display: flex;
    align-items: center;
    padding: 10px 0px 10px 0px;
    width: 100%;
    border-bottom: 1px solid #474747;
}

.one-chapter:hover {
    background-color: #444444;
    cursor: pointer;
}

.not-bought {
    display: flex;
    align-items: center;
    width: 100%;
}

.not-bought-cost {
    display: flex;
    align-items: center;
    justify-content: center;
    --background-color: brown;
    border-radius: 15px;
    color: white;
    width: 78px;
    height: 25px;
    margin-left: auto;
    min-width: 78px;
}

.bought {
    display: flex;
    align-items: center;
    width: 100%;
}

.bought-can-read {
    display: flex;
    align-items: center;
    justify-content: center;
    --background-color: royalblue;
    border-radius: 15px;
    color: white;
    width: 78px;
    min-width: 78px;
    height: 25px;
    margin-left: auto;

}

.free-chapter {
    display: flex;
    align-items: center;
    width: 100%;

}

.free-read {
    display: flex;
    align-items: center;
    justify-content: center;
    --background-color: darkgreen;
    border-radius: 15px;
    color: white;
    min-width: 78px;
    width: 78px;
    height: 25px;
    margin-left: auto;
}
</style>