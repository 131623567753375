import { createRouter, createWebHashHistory, createWebHistory } from "vue-router"
import login from '@/Components/Pages/login'
import main from '@/Components/Pages/main'
import book from '@/Components/Pages/book'
import readChapter from '@/Components/Pages/readChapter'
import createBook from '@/Components/Pages/createBook'
import createChapter from '@/Components/Pages/createChapter'
import catalog from '@/Components/Pages/catalog'
import registration from '@/Components/Pages/registration'
import payment from '@/Components/Pages/payment'
import advcsuccess from '@/Components/Payments/Advc-payment-success'
import advcfail from '@/Components/Payments/Advc-payment-fail'
import bookstatistic from '@/Components/Pages/bookStatistic'
import editbook from '@/Components/Pages/editBook'
import editchapter from '@/Components/Pages/editChapter'

const routes = [
    {
        path: '/',
        component: main
    },
    {
        path: '/login',
        component: login,
        meta: { requiresToken: false },
    },
    {
        path: '/registration',
        component: registration,
        meta: { requiresToken: false },    
    },
    {
        path: '/payment',
        component: payment,
        meta: { requiresToken: false },    
    },
    {
        path: '/book/:id',
        component :book,
    },
    {
        name: 'CreateChapter',
        path: '/book/:id/createchapter',
        component :createChapter,
    },
    {
        name: 'Chapter',
        path: '/book/:id/chapter/:chapterId',
        component :readChapter,
    },
    {
        name: 'CreateBook',
        path: '/createbook',
        component: createBook,
    },
    {
        name: 'Catalog',
        path: '/catalog',
        component: catalog,
    },
    {
        name: 'AdvcSuccess',
        path: '/payment/advc/success',
        component: advcsuccess,
    },
    {
        name: 'AdvcFail',
        path: '/payment/advc/fail',
        component: advcfail,
    },
    {
        name: 'BookStatistic',
        path: '/bookstatistic',
        component: bookstatistic
    },
    {
        name: 'EditBook',
        path: '/editbook/:id',
        component: editbook
    },
    {
        name: 'EditChapter',
        path: '/editbook/:id/editchapter/:chapterId',
        component: editchapter,
    },
]

const router = createRouter(
    {
        routes,
        history: createWebHistory()
    })
export default router;