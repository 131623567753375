<template>
    <div>
        <div v-for="(item) in commentsToShow">
            <div v-if="item.text != ''" style="display: flex; margin-bottom: 30px; margin-left: 30px;">
                <div style="display: flex; flex-direction: column;">
                    <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="3.5" cy="3.5" r="3.5" fill="black" />
                        <line x1="3.5" y1="6" x2="3.5" y2="11" stroke="black" stroke-width="3px" />
                    </svg>
                    <span class="line">
                    </span>
                    <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="3.5" cy="7.5" r="3.5" transform="rotate(-180 3.5 7.5)" fill="black" />
                        <line x1="3.5" y1="5" x2="3.5" y2="-1.31134e-07" stroke="black" stroke-width="3" />
                    </svg>
                </div>
                <div class="one-comment">
                    <div style="display: flex; flex-wrap: wrap; max-width:100%; align-items: center;">
                        <h2 style="color: #E39600; margin-left: 5px; word-break: break-word;">
                            {{ item.authorComment }}
                        </h2>
                        <h3 style="color: #A6A6A6; margin-left: 12px; white-space: nowrap;">
                            {{ item.time }}
                        </h3>
                    </div>
    
                    <div v-if="item.liked" style="display: flex; align-items: center;">
                        <svg width="40" height="40" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M40 23.2C40 21.1 38.3 20 36 20H29.3C29.8 18.2 30 16.5 30 15C30 9.2 28.4 8 27 8C26.1 8 25.4 8.1 24.5 8.6C24.2 8.8 24.1 9 24 9.3L23 14.7C21.9 17.5 19.2 20 17 21.7V36C17.8 36 18.6 36.4 19.6 36.9C20.7 37.4 21.8 38 23 38H32.5C34.5 38 36 36.4 36 35C36 34.7 36 34.5 35.9 34.3C37.1 33.8 38 32.8 38 31.5C38 30.9 37.9 30.4 37.7 29.9C38.5 29.4 39.2 28.5 39.2 27.5C39.2 26.9 38.9 26.3 38.6 25.8C39.4 25.2 40 24.2 40 23.2ZM37.9 23.2C37.9 24.5 36.6 24.6 36.4 25.2C36.2 25.9 37.2 26.1 37.2 27.3C37.2 28.5 35.7 28.5 35.5 29.2C35.3 30 36 30.2 36 31.4V31.6C35.8 32.6 34.3 32.7 34 33.1C33.7 33.6 34 33.8 34 34.9C34 35.5 33.3 35.9 32.5 35.9H23C22.2 35.9 21.4 35.5 20.4 35C19.6 34.6 18.8 34.2 18 34V23.5C20.5 21.6 23.7 18.8 24.9 15.3V15.1L25.8 10.1C26.2 10 26.5 10 27 10C27.2 10 28 11.2 28 15C28 16.5 27.7 18.1 27.2 20H27C26.4 20 26 20.4 26 21C26 21.6 26.4 22 27 22H36C37 22 37.9 22.5 37.9 23.2Z"
                                fill="#00FF1A" />
                            <path
                                d="M16 38H10C8.9 38 8 37.1 8 36V22C8 20.9 8.9 20 10 20H16C17.1 20 18 20.9 18 22V36C18 37.1 17.1 38 16 38ZM10 22V36H16V22H10Z"
                                fill="#00FF1A" />
                        </svg>
                        <h3 style="color: #00FF1A;">Сподобалось</h3>
                    </div>
                    <div v-else class="is-dislike" style="display: flex; align-items: center;">
                        <svg width="40" height="40" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.99998 26.8C9.99998 28.9 11.7 30 14 30H20.7C20.2 31.8 20 33.5 20 35C20 40.8 21.6 42 23 42C23.9 42 24.6 41.9 25.5 41.4C25.8 41.2 25.9 41 26 40.7L27 35.3C28.1 32.5 30.8 30 33 28.3V14C32.2 14 31.4 13.6 30.4 13.1C29.3 12.6 28.2 12 27 12H17.5C15.5 12 14 13.6 14 15C14 15.3 14 15.5 14.1 15.7C12.9 16.2 12 17.2 12 18.5C12 19.1 12.1 19.6 12.3 20.1C11.5 20.6 10.8 21.5 10.8 22.5C10.8 23.1 11.1 23.7 11.4 24.2C10.6 24.8 9.99998 25.8 9.99998 26.8ZM12.1 26.8C12.1 25.5 13.4 25.4 13.6 24.8C13.8 24.1 12.8 23.9 12.8 22.7C12.8 21.5 14.3 21.5 14.5 20.8C14.7 20 14 19.8 14 18.6V18.4C14.2 17.4 15.7 17.3 16 16.9C16.3 16.4 16 16.2 16 15.1C16 14.5 16.7 14.1 17.5 14.1H27C27.8 14.1 28.6 14.5 29.6 15C30.4 15.4 31.2 15.8 32 16V26.5C29.5 28.4 26.3 31.2 25.1 34.7V34.9L24.2 39.9C23.8 40 23.5 40 23 40C22.8 40 22 38.8 22 35C22 33.5 22.3 31.9 22.8 30H23C23.6 30 24 29.6 24 29C24 28.4 23.6 28 23 28H14C13 28 12.1 27.5 12.1 26.8Z"
                                fill="#FF0000" />
                            <path
                                d="M34 12H40C41.1 12 42 12.9 42 14V28C42 29.1 41.1 30 40 30H34C32.9 30 32 29.1 32 28V14C32 12.9 32.9 12 34 12ZM40 28V14H34V28H40Z"
                                fill="#FF0000" />
                        </svg>
                        <h3 style="color: #f00;">Не сподобалось</h3>
                    </div>
                    <h3 style="margin-left: 5px;">{{ item.text }}</h3>
                </div>
            </div>
    
        </div>
        <div class="show-more-button" @click="ShowMore">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                class="origin-center rotate-180 text-gray-600" :class="{ active: !showMore }">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="m10 7.37 7.316 6.86 1.368-1.46L10 4.63l-8.684 8.14 1.368 1.46L10 7.37Z"></path>
            </svg>
            <h3 style="margin-left: auto; margin-right: auto;">{{ showMore ? 'Приховати коментарі' : 'Показати всі комментарі' }}</h3>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showMore: false,
            commentsToShow: [],
            commentsPerPage: 3,
        };
    },
    props: {
        comments: {
            required: true
        }
    },
    watch: {
        comments: {
            immediate: true,
            handler(newComments, oldComments) {
                if (newComments !== undefined) {
                    this.commentsToShow = newComments.slice(0, this.commentsPerPage);
                } else {
                    console.error("Invalid comments data:", newComments);
                }
            },
        },
    },
    methods: {
        ShowMore() {
            this.showMore = !this.showMore;
            if (this.showMore) {
                this.commentsToShow = this.comments;
            } else {
                this.commentsToShow = this.comments.slice(0, this.commentsPerPage);
            }
        }
    }
}
</script>

<style scoped>
.one-comment {
    margin-left: 15px;
    padding: 10px 0px 10px 0px;
}

.one-comment h3,
h2,
div,
svg {
    background-color: var(--background-color);
}

.one-comment h3 {

    font-size: 20px;
}

.line {
    margin-left: 2px;
    background-color: black;
    width: 3px;
    height: 100%;
}

.show-more-button {
    width: 100%;
    height: 50px;
    border-radius: 20px;
    border: 0px;
    background-color: #181818;
    display: flex;
    align-items: center;
    padding-left: 25px;
}
.rotate-180 {
    transform-origin: center;
    transition: transform 0.2s ease;
}

.rotate-180.active {
    transform: rotate(180deg);
}
</style>