<template>
    <div class="container-readchapter" style="margin-top: 60px;">
        <div class="content-readchapter">
            <div style="margin-top: 30px;">
                <div class="textStyles-readchapter" :style="textStyles"  v-html="text">
            </div>
            </div>
        </div>
    </div>
    <ReadChapterMenu class="menu-panel-readchapter" @changeFontSize="readFontSize"></ReadChapterMenu>
</template>

<script>
import backendURL from '@/config';
import axios from 'axios';
import ReadChapterMenu from '../ReadChapter-menu.vue';
export default {
    data() {
        return {
            text: 'Загрузка',
            params: {
                fontSize: 18,
                lineHeight: 25,
                fontFamily: "'Montserrat', sans-serif",
            }
        }
    },
    components: {
        ReadChapterMenu
    },
    async created() {
        const route = backendURL + 'Book/GetChapterToRead'
        var request = {
            bookId: this.$route.params.id,
            chapterId: this.$route.params.chapterId
        }
        var res = await axios.post(route, request);
        this.text = res.data.result.text;
    },
    methods: {
        readFontSize(params) {
            this.params = params;
        }
    },
    computed: {
        textStyles() {
            return {
                fontFamily: this.params.fontFamily,
                color: 'white',
                fontSize: this.params.fontSize + 'px',
                lineHeight: this.params.lineHeight + 'px',
                whiteSpace: 'pre-wrap',
                alignItems: 'left',

            };
        }
    }
}

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200&family=Open+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

.container-readchapter {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}
.content-readchapter {
    max-width: 1280px;
}
.textStyles-readchapter *{font-family: inherit !important;}
.menu-panel-readchapter {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9999;
}
</style>