<template>
  <div class="align-content">
    <div class="container">
      <div v-if="!isMethodSelected">
        <div class="paypal-content">
          <div class="paypal-button" style="display: flex; align-items: center;" @click="isMethodSelected = !isMethodSelected, isPayPal = !isPayPal">
            <div style="justify-content: center; width: 300px; display: flex; padding: 10px 50px 10px 50px; border-radius: 20px; background-color: #111111; align-items: center;">
              <img src="../Images/paypal-svgrepo-com.svg" alt="SVG Image" style="width: 40px;background-color: #111111; ">
              <h2 style="background-color: #111111; margin-left: 10px;">PayPal</h2>
            </div>
          </div>
          <div class="paypal-button" style="margin-top: 10px; display: flex; align-items: center;" @click="isMethodSelected = !isMethodSelected, isAdvC = !isAdvC">
            <div style="justify-content: center; width: 300px; display: flex; padding: 10px 50px 10px 50px; border-radius: 20px; background-color: #111111; align-items: center;">
              <img src="../Images/Advc-light.svg" alt="SVG Image" style="width: 40px;background-color: #111111; ">
              <h2 style="background-color: #111111; margin-left: 10px;">AdvCash</h2>
            </div>
          </div>
          <div v-if="role == 'Admin'" class="paypal-button" style="margin-top: 10px; display: flex; align-items: center;" @click="isMethodSelected = !isMethodSelected, isAdminOption = !isAdminOption">
            <div style="justify-content: center; width: 300px; display: flex; padding: 10px 50px 10px 50px; border-radius: 20px; background-color: #111111; align-items: center;">
              <h2 style="background-color: #111111; margin-left: 10px;">ADMIN</h2>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="isPayPal" style="display: flex; align-items: center; justify-content: center; flex-flow: column;">
          <h2 style="margin-bottom: 10px;" class="back-button" @click="isMethodSelected = !isMethodSelected, isPayPal = !isPayPal">←</h2>
          <div>
            <paypal></paypal>
          </div>
        </div>
        <div v-if="isAdvC" style="display: flex; align-items: center; justify-content: center; flex-flow: column;">
          <h2 style="margin-bottom: 10px;" class="back-button" @click="isMethodSelected = !isMethodSelected, isAdvC = !isAdvC">←</h2>
          <div>
            <Advc></Advc>
          </div>
        </div>
        <div v-if="isAdminOption" style="display: flex; align-items: center; justify-content: center; flex-flow: column;">
          <h2 style="margin-bottom: 10px;" class="back-button" @click="isMethodSelected = !isMethodSelected, isAdminOption = !isAdminOption">←</h2>
          <div>
            <AddToBalance></AddToBalance>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import paypal from "@/Components/Payments/Paypal.vue"
import Advc from "../Payments/Advc.vue";
import AddToBalance from "../Payments/Add-to-balance.vue";
import axios from "axios";
import backendURL from "@/config";
export default {
  data() {
    return {
      isMethodSelected: false,
      isAdvC: false,
      isPayPal: false,
      isAdminOption: false,
      login: '',
      role: ''
    };
  },
  components: {
    paypal, Advc, AddToBalance
  },
  beforeRouteEnter(to, from, next) {
    const token = $cookies.get('token');
    if (token) {
      next();
    } else {
      next({ path: '/' });
    }
  },
  mounted() {
    axios.get(backendURL + 'user/GetUserByToken').then(response => {
      this.login = response.data.result.login;
      this.role = response.data.result.role;
    })
      .catch(error => {
        console.error(error);
      });
  }
};
</script>
<style scoped>
.align-content {
  margin-top: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  max-width: 1280px;
  width: 100%;
}

.back-button {
  border: 1px solid grey;
  border-radius: 90px;
  color: white;
  padding: 5px 11px 11px 11px;
  background-color: #111111;
  transition: all 0.3s ease;
}

.back-button:hover {
  border: 1px solid white;
  background-color: #181818;
  cursor: pointer;
}

.paypal-content:hover {
  cursor: pointer;
}

.paypal-button {

  justify-content: center;
}
</style>
  